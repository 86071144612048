import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './router/intercept.js';
import routerServer from './utils/routerServer';
import store from './store';
import './assets/css/reset.css';
import ElementUI, { Message } from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
import echarts from 'echarts';
import Cookies from 'js-cookie';
import { UTable, UTableColumn } from 'umy-ui';
import http from './utils/http';
import filters from './filter/index';
import data from './store/data';
Vue.use(filters);
import installTinymce from './components/Tinymce/index'
Vue.use(installTinymce)
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)

// 为了实现Class的私有属性
const showMessage = Symbol('showMessage')
/**
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
let messageInstance = null;
class DonMessage {
  success (options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    this[showMessage]('error', options, single)
  }
  [showMessage] (type, options, single) {
    if (messageInstance && single) {
      messageInstance.close()//先把原来的关闭
    }
    messageInstance = Message[type](options)//再创建新的消息
  }
}

import tool from './utils/tool';
Object.keys(tool).forEach((key) => {
  Vue.prototype['$' + key] = tool[key];
});
Vue.prototype.$http = http;
Vue.prototype.$localData = data;
Vue.prototype.$message = new DonMessage()
Vue.prototype.$cookies = Cookies;
Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
Vue.use(routerServer, { router, store });
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
