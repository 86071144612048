<template>
  <div class="login">
    <div class="main">
      <div class="left">
        <div class="title" style="width:370px">教育装备数据填报</div>
        <div class="login-tab">
          <ul>
            <li :class="{'on':displayVisible}" @click="xy1">账号密码登录</li>
            <li :class="{'on':displayVisible1}" @click="xy2">手机验证登录</li>
          </ul>
        </div>
        <el-form v-show="displayVisible"
          label-width="0"
          :model="formData"
        >
          <el-form-item>
            <el-input
              v-model="formData.username"
              prefix-icon="el-icon-user"
              placeholder="请输入您的账号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="formData.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入您的密码"
              autocomplete="off"
              show-password
            />
          </el-form-item>
          <el-form-item>
            <el-button
              class="login_btn"
              @click="submitForm()"
            >登录</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
            type="text"
            class="yhsc"
              @click="xzyhsc()"
            >用户手册</el-button>
          </el-form-item>
        </el-form>
        <el-form v-show="displayVisible1"
          label-width="0"
          :model="formData"
        >
          <el-form-item>
            <el-input
              v-model="formData.phone"
              prefix-icon="el-icon-user"
              placeholder="请输入您的手机号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              style="width: 60%;"
              v-model="formData.yzm"
              prefix-icon="el-icon-lock"
              placeholder="请输入验证码"
              autocomplete="new-password"
            />
            <el-button type="info" style="margin-left:3%;width: 36%;" round @click="sendMessage1()" :disabled="upVisible">{{isToggle ? toggle:'发送验证码'}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              class="login_btn"
              @click="handleLogin2()"
            >登录</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
            type="text"
            class="yhsc"
              @click="xzyhsc()"
            >用户手册</el-button>
          </el-form-item>
        </el-form>

        <el-dialog :close-on-click-modal="false" :visible.sync="dialogChangePassWordVisible" :title="'修改密码'">
          <el-form ref="ruleForm" :model="ruleForm" status-icon :rules="rules" label-width="100px" class="demo-ruleForm">
            <el-form-item label="新密码" prop="pass">
              <el-input v-model.trim="ruleForm.pass" type="password" autocomplete="off" />
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input v-model.trim="ruleForm.checkPass" type="password" autocomplete="off" />
            </el-form-item>
            <el-form-item label="旧密码" prop="oldPass">
              <el-input v-model.trim="ruleForm.oldPass" type="password" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit('ruleForm')">提交</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { sendCode, newLogin } from '@/api/user'
import { xzyhsc } from '@/api/organization'

export default {
  name: 'Login',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{6,15}$/)) {
        callback(new Error('必须包含大小写字母和数字，并且不得少于6位字符，大于15位字符'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        username: '',
        password: '',
        phone: '',
        yzm: ''
      },
      loginLoading: false,
      items: [
        {
          p: '教育装备数据填报系统PBM',
          backgroundImage:
            'url(' + require('../assets/images/login1.png') + ')'
        }
      ],
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPass: ''
      },
      dialogChangePassWordVisible: false,
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        oldPass: [
          { validator: validatePass3, trigger: 'blur' }
        ]
      },
      displayVisible: true,
      displayVisible1: false,
      curCount1: 0,
      toggle: '',
      InterValObj1: null,
      upVisible: false,
      isToggle: false // 是否切换提醒
    }
  },
  created() { },
  methods: {
    xy1() {
      this.displayVisible = true
      this.displayVisible1 = false
    },
    xy2() {
      this.displayVisible = false
      this.displayVisible1 = true
    },
    sendMessage1() {
      var phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      var count = 60

      this.curCount1 = count
      if (!phoneReg.test(this.formData.phone)) {
        Message({
          message: '请输入有效的手机号码',
          type: 'error',
          duration: 5 * 1000
        })
        return false
      }

      const loading = this.$loading({
        lock: true,
        text: '发送中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      sendCode({ phone: this.formData.phone }).then(response => {
        console.log(response)
        if(response.msg.includes("error")){
          Message({
            message: response.msg,
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          Message({
            message: '发送成功',
            type: 'success',
            duration: 5 * 1000
          })
        }
        loading.close()

        this.upVisible = true
        this.isToggle = !this.isToggle
        this.toggle = this.curCount1 + '秒再获取'
        this.InterValObj1 = window.setInterval(this.SetRemainTime1, 1000)
      }).catch(response => {
        loading.close()
      })
    },
    SetRemainTime1() {
      if (this.curCount1 === 0) {
        this.upVisible = false
        window.clearInterval(this.InterValObj1)
        this.isToggle = !this.isToggle
        this.toggle = '重新发送'
      } else {
        this.curCount1--
        this.toggle = this.curCount1 + '秒再获取'
      }
    },
    async handleLogin2() {
      const loading = this.$loading({
        lock: true,
        text: '登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      var backUrl = this.getQueryString('back')
      this.$http
        .post('/ltcloud/user/newLogin', { password: this.formData.yzm, userName: this.formData.phone }).then(res => {
        if (res.code === 20000) {
          Message({
            message: '登录成功',
            type: 'success',
            duration: 5 * 1000
          })
          
          if (backUrl === null || backUrl === '') {
            document.cookie = 'userInfo=' + JSON.stringify(JSON.parse(res.data.userInfo))
            this.getToken(res.data.token)
            localStorage.stop = false
          } else {
            window.location.href = backUrl
          }
          loading.close()
        }
      }).catch(response => {
        loading.close()
      })
    },
    getQueryString(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    //下载用户手册
    xzyhsc(){
      xzyhsc()
    },
    // 登录
    submitForm() {
      if (this.formData.username === '') {
        Message('请输入账号')
        return
      }
      if (this.formData.password === '') {
        Message('请输入密码')
        return
      }
      this.loginLoading = true
      var backUrl = this.getQueryString('back')
      this.$http
        .post('/sso/doLogin', { name: this.formData.username, pwd: this.formData.password })
        .then((res) => {
          if (res.code === 200) {
            // if (res.data.firstLogin === '1') {
            //   this.$message.info('第一次登录，请先修改密码！')
            //   this.dialogChangePassWordVisible = true
            // } else {
              if (backUrl === null || backUrl === '') {
                document.cookie = 'userInfo=' + JSON.stringify(JSON.parse(res.data.userInfo))
                this.getToken(res.data.token)
                localStorage.stop = false
              } else {
                window.location.href = backUrl
              }
            // }
          } else {
            this.loginLoading = false
          }
        })
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post('/ltcloud/user/editPasssWord', { newPassWord: this.ruleForm.pass, oldPassWird: this.ruleForm.oldPass }
          ).then((res) => {
            this.dialogChangePassWordVisible = false
            this.ruleForm = {}

            const loading = this.$loading({
              lock: true,
              text: '登录中......',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })

            var backUrl = this.getQueryString('back')
            this.$http
              .post('/sso/doLogin', { name: res.data.userName, pwd: res.data.password })
              .then((res) => {
                if (res.code === 200) {
                  loading.close()
                  if (backUrl === null || backUrl === '') {
                    document.cookie = 'userInfo=' + JSON.stringify(JSON.parse(res.data.userInfo))
                    this.getToken(res.data.token)
                    localStorage.stop = false
                  } else {
                    window.location.href = backUrl
                  }
                } else {
                  this.loginLoading = false
                }
              })
          }).catch(res => {
            Message({
              message: '修改失败',
              type: 'error',
              duration: 5 * 1000
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 放置token
    getToken(data) {
      document.cookie = 'token=' + data
      document.cookie = 'refreshToken=' + data
      document.cookie = 'comeTime=' + new Date().getTime()
      this.getUserInfo(data)
    },
    getProjectUnHandleByUser(userID) {
      this.totalNum = 0
      this.initArr = []
      this.$http
        .post('bma-user-service/user/getProjectUnHandleByUser', {
          userId: userID
        })
        .then((res) => {
          if (res.code === 200) {
            const projectHandles = res.data.projectHandles
            if (projectHandles.length > 0) {
              this.initArr = projectHandles
              projectHandles.forEach((item) => {
                this.totalNum += item.unHandle
              })
            } else {
              this.totalNum = 0
              this.initArr = []
            }
            this.$store.commit('setTotalNum', this.totalNum)
          } else {
            this.loginLoading = false
          }
        })
    },
    getlist(userId) {
      this.$http
        .post('bma-user-service/user/web/searchRoleResourcesByUserForWeb', {
          userId: userId
        })
        .then((res) => {
          const resourceIds = []
          const menuSon = []
          if (res.data && res.data.length > 0) {
            res.data[0].children.forEach((item) => {
              if (item.resourceId === 3) {
                item.children.forEach((son) => {
                  menuSon.push(son.resourceId)
                })
              }
              resourceIds.push(item.resourceId)
            })
          }
          // 要求强制刷新时，菜单也存在
          localStorage.menuSon = menuSon
          // this.$store.commit("setMenuChildren", menuSon);
          document.cookie = 'resourceIds=' + resourceIds
          this.resourceLists = resourceIds
        })
    },
    // 获取用户信息
    getUserInfo(token) {
      this.$store.commit('setUserInfo', this.$localData.userinfodata)
      this.roundIds(this.$localData.userinfodata, this.$localData.userinfodata.userId)

      this.getProjectUnHandleByUser(this.$localData.userinfodata.userId)
      this.loginLoading = false
      setTimeout(() => {
        this.$initWebSocket()
      }, 2000)

      if (this.$localData.userinfodata.projectTopic) {
        this.$router.push('/project')
        localStorage.code =
          this.$localData.userinfodata.projectTopic.length > 0
            ? this.$localData.userinfodata.projectTopic[0].projectCode
            : ''
      } else {
        this.$router.push('/super')
      }
    },
    roundIds(data, userId) {
      let num = 0
      if (data.projectTopic) {
        data.projectTopic.forEach((item) => {
          num++
          item.id = num
        })
      } else {
        this.getlist(userId)
        this.$router.push('/super')
      }
    },
    sixGetInfo() {
      this.$http
        .post('/bma-auth-service/oauth2/refreshToken', {
          clientId: 'devops',
          clientSecret: 'hengtaidevops',
          refreshToken: this.$getCookie('refreshToken')
        })
        .then((res2) => {
          if (res2.code === 200) {
            this.$cookies.set('token', res2.data.accessToken, {
              expires: 365
            })
            this.$cookies.set('refreshToken', res2.data.refreshToken, {
              expires: 365
            })
          } else {
            this.$router.push('/')
          }
        }).catch(response => {
          this.$router.push('/')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  width: 100%;
  background-image: url(../assets/images/bg.png);
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  .main {
    height: 80%;
    width: 70%;
    background-image: url(../assets/images/yptbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .left {
      height: 50%;
      width: 30%;
      position: absolute;
      left: 10%;
      top: 20%;
      .title {
        height: 80px;
        width: 100%;
        line-height: 80px;
        text-align: center;
        font-size: 36px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 10px;
      }
      .el-form {
        margin-top: 30px;
      }
      .login_btn {
        width: 120px;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        margin: auto;
        background: linear-gradient(to right, #ef80b0 0%, #fcba6a 100%);
        color: #fff;
        border: none;
      }
      .yhsc {
        width: 120px;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        border: none;
      }
    }
  }
}
.el-button--info {
  color: #FFF;
  background-color: #332856;
  border-color: #90939938;
}
.el-button--info:hover {
  color: #FFF;
  background-color: #332856;
  border-color: #90939938;
}
.el-button--info.is-active {
  color: #FFF;
  background-color: #332856;
  border-color: #90939938;
}
.el-button--info:focus, .el-button--info:hover {
  color: #FFF;
  background-color: #332856;
  border-color: #90939938;
}
</style>

<style>


.login-tab {
  width: 100%;
  height: auto;
  overflow: hidden
}

.login-tab ul li {
  width: 48%;
  float: left;
  background: #f0f0f0;
  border-radius: 5px;
  height: 48px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
}

.login-tab ul li.on {
  background: #6900d1;
  color: #fff;
}

.login-tab ul li:hover {
  background: #6900d1;
  color: #fff;
  cursor: pointer
}

.login-tab ul li:nth-child(even) {
  float: right;
}
</style>
