import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null,
    isShowDet: false,
    alarmStatus: false,
    roomId: '',
    detEquipmentCode: '',
    projectId: '',
    totalNum: 0,
    accessToken: '',
    alarmInfo: {},
    menuChildren: [],
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setIsShowDet(state, flag) {
      state.isShowDet = flag;
    },
    setRoomId(state, flag) {
      state.roomId = flag;
    },
    setProjectId(state, flag) {
      state.projectId = flag;
    },
    // 消息总数
    setTotalNum(state, num) {
      state.totalNum = num;
    },
    // 获取视频的token值
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    // 子菜单
    setMenuChildren(state, children) {
      state.menuChildren = children;
    },
    setDetEquipmentCode(state, flag) {
      state.detEquipmentCode = flag;
    },
    setAlarmInfo(state, flag) {
      state.alarmInfo = flag;
    },
    setAlarmStatus(state, flag) {
      state.alarmStatus = flag;
    },
  },
  actions: {},
  modules: {},
});
