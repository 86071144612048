import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import tool from './tool';
import store from '../store';
import router from '../router/index';
import Cookies from 'js-cookie';
import {
  Message
} from 'element-ui';
let v = new Vue();
let baseURL = '';
let loading = null;
window.isRefreshing = false;
let address = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_URL : process.env.VUE_APP_URL_PROD;
let url = window.location.hash;
if (process.env.NODE_ENV == 'development') {
  baseURL = process.env.VUE_APP_URL;
} else {
  baseURL = process.env.VUE_APP_URL_PROD;
}


let http = axios.create({
  baseURL: baseURL,
  timeout: 50000
});
http.defaults.withCredentials = true;

http.interceptors.request.use(
  (config) => {
    console.log(config);
    
    loading = filterLoginUrl(config.url) ?
      v.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      }) :
      false;
      if(config.url.indexOf('sso') > -1){
        config.data = qs.stringify(config.data);
      }else{
          config.headers['Content-Type']='application/json'
         
      }

       let token = tool.getCookie('X-Token');
    
    console.log(config.url)
    // if (config.method === 'post' && config.data && config.data.dataType != 1) {
    //   if (
    //     config.url === 'bma-user-service/resource/addResource' ||
    //     config.url === 'bma-user-service/resource/updateResource' ||
    //     config.url === 'bma-user-service/user/updateUserInfo' ||
    //     config.url === 'bma-distribution-service/include/addIncludeProjectEquipment' ||
    //     config.url.indexOf('handleAlarm') > -1
    //   ) {
    //     console.log(config);
    //   } else {
    //     config.data = qs.stringify(config.data);
    //   }
    //   // config.data = qs.stringify(config.data);
    //   delete config.data.dataType;
    // }
    
    if (token) {
      // config.headers['X-Token'] = token;
    } else {
      if (window.location.hash === '#/') {
        return config;
        // return false;
      } else {
        toLogin();
      }
    }
    return config;
  },
  (error) => Promise.error(error),
);
http.interceptors.response.use(
  (res) => {
    // console.log(res)
    loading ? loading.close() : false;
    if (res.headers['content-type'] == 'application/vnd.ms-excel') {
      return res;
    }
    if (res.data.code != '200') {
      if (
        res.config.url ===
        'bma-distribution-service/realTimeMonitoring/exportMonitorTable'
      ) {
        Message({
          message: '导出失败，设备编码不能为空',
          type: 'warning',
        });
        return res.data;
      } else {
        
        Message({
          message: res.data.msg,
          type: 'warning',
        });
        if(res.data.code === 50102){
          router.push('/');
        }
        // }
        return res.data;
      }
    } else {

      return res.data;
    }
  },
  (error) => {
    loading ? loading.close() : false;
    console.log(error);
    let refreshToken = Cookies.get('refreshToken');
    // require的时候   http://distribution-webroot-round01.createdpark.net/#/
  
    if (location.href === 'http://localhost:8080/#/') {
      return false;
    }
    if (error.response.data.code == 401) {
      if (!window.isRefreshing && window.location.hash !== '#/') {
        console.log(error.response)
        getToken(refreshToken, error.response.config.url);
      }
    } else if (error.response.data.code == 302) {
      toLogin();
    }
    return Promise.resolve(error);
  },
);

function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = true;
    axios.post(url, qs.stringify(data)).then(
      (response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          Message.error(response.statusText);
        }
      },
      (err) => {
        reject(err);
      },
    );
  });
}

function getToken(refreshToken, url) {
  window.isRefreshing = true;
  // /api
  post(address + 'bma-auth-service/oauth2/refreshToken', {
      clientId: 'devops',
      clientSecret: 'hengtaidevops',
      refreshToken: refreshToken,
    })
    .then((res2) => {
      if (res2.code === 200) {
        // document.cookie = 'token=' + res2.data.accessToken;
        Cookies.set('token', res2.data.accessToken, {
          expires: 365,
        });
        Cookies.set('refreshToken', res2.data.refreshToken, {
          expires: 365,
        });
        if (location.href.indexOf("#loaded") == -1) {
          location.href = location.href + "#loaded";
          location.reload();
        }
      } else {
        // alert(1)   退出登录的时候这边会出现这个问题
        // alert(url)
        toLogin(url);
      }
      // token失效
    })
    .catch(function (err) {
      console.log(err);
      window.isRefreshing = false;
      toLogin();
    });
}

function toLogin(url) {
  console.log(url)
  window.localStorage.clear();
  Cookies.set('comeTime', '');
  // http.post('/bma-auth-service/sys/signOut').then((res) => {
  store.commit("setAlarmStatus", false);
  url === '/bma-auth-service/sys/signOut' ? "" : Message({
    type: 'warning',
    message: '登录过期请重新登录！',
  });
  tool.disconnect()
  router.push('/');
  loading.close();
  localStorage.stop = true;
  tool.clearAllCookie();

}

function filterLoginUrl(url) {
  let flag;
  switch (url) {
    case '/bma-auth-service/sys/signIn':
      flag = false;
      break;
    case '/bma-auth-service/oauth2/getCode':
      flag = false;
      break;
    case '/bma-auth-service/oauth2/getToken':
      flag = false;
      break;
    case '/bma-user-service/user/getProjectUnHandleByUser':
      flag = false;
      break;
    case '/bma-auth-service/oauth2/getUserInfo':
      flag = false;
      break;
    default:
      flag = true;
      break;
  }
  console.log(flag)
  return flag;
}
// -------------------------------------------待删除
// 被挂起的请求数组
let subscribesArr = [];
// push所有请求到数组中
function subscribeTokenRefresh(cb) {
  subscribesArr.push(cb);
}

// 用新token发起请求
function reloadSubscribesArr(token) {
  console.log('0000000');
  console.log(subscribesArr);
  subscribesArr.forEach((item) => {
    return http.request(item);
  });
  // subscribesArr.map((cb) => return http.request(cb););
}
/**
 * 快过期了 和 已经过期了
 * @returns {boolean}
 */
function isTokenExpired() {
  let expiredTime = Cookies.get('comeTime');
  if (expiredTime) {
    let nowTime = new Date().getTime();
    // 单位是小时
    let willExpired = (nowTime - expiredTime) / 3600000 > 8;
    console.log('时间差' + (nowTime - expiredTime) / 3600000);
    // alert('时间差' + (nowTime - expiredTime) / 3600000);
    console.log('现在时间' + nowTime);
    console.log('之前时间 ' + expiredTime);
    return willExpired;
  }
  return false;
}
export default http;