import axios from 'axios'
import tool from "./tool";

const service=axios.create({
    responseType:'arraybuffer',
    baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_URL : process.env.VUE_APP_BASE_API_PROD,
})

service.interceptors.request.use(
    config => {
        let token = tool.getCookie('X-Token');
        config.headers['X-TOKEN'] = token
        return  config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(response=>{
    const headers=response.headers;
    let reg = RegExp(/application\/json/);
    if(headers['content-type'].match(reg)){
        response.data=unitToString(response.data)
    }else{
        let fileDownload=require('js-file-download');
        let fileName=headers['content-disposition'].split(';')[1].split('filename=')[1];
        let contentType=headers['content-type'];
        fileName=decodeURIComponent(fileName);
        fileDownload(response.data,fileName,contentType);
    }
},error => {
})


function unitToString(unitArray){
    let encodedString=String.fromCharCode.apply(null,new Uint8Array(unitArray));
    let decodedString=decodeURIComponent(escape(encodedString));
    return JSON.parse(decodedString);
}

export default service;
