import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Home2 from '../views/super/Home.vue';

Vue.use(VueRouter);
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/project',
    name: 'project',
    component: () =>
      import('../views/super/Home.vue'),
      redirect:'../super/general',
  },
  // 404页面
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/all',
    name: 'all',
    component: () => import('../views/all.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () =>
          import('../views/dashboard.vue'),
      }
    ],
  },

  // super admin的路由
  {
    path: '/super',
    name: 'super',
    component: Home2,
    children: [
      {
        path: 'general',
        name: 'general',
        component: () =>
          import('../views/super/general.vue'),
      },
      {
        path: 'department',
        name: 'department',
        component: () =>
          import('../views/super/maSys/department.vue'),
      },
      {
        path: 'Management',
        name: 'Management',
        component: () =>
          import('../views/super/maSys/deptManagement.vue'),
      },
      //区县组织架构和用户管理
      {
        path: 'deptManagement',
        name: 'deptManagement',
        component: () =>
          import('../views/super/maSys/deptManagement.vue'),
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: () =>
          import('../views/super/maSys/userManagement.vue'),
      },
      //市级组织架构和用户管理
      {
        path: 'deptManagementCity',
        name: 'deptManagementCity',
        component: () =>
          import('../views/super/maSys/deptManagementCity.vue'),
      },
      {
        path: 'userManagementCity',
        name: 'userManagementCity',
        component: () =>
          import('../views/super/maSys/userManagementCity.vue'),
      },
      {
        path: 'role',
        name: 'role',
        component: () =>
          import('../views/super/maSys/roleManage/index.vue'),
      },
      {
        path: 'menuManage',
        name: 'menuManage',
        component: () =>
          import('../views/super/maSys/menuManage/menuManger.vue'),
      },
      {
        path: 'flowManage',
        name: 'flowManage',
        component: () =>
          import('../views/super/maSys/flowManage/list.vue'),
      },
      {
        path: 'maDict',
        name: 'maDict',
        component: () =>
          import('../views/super/maSys/maDict/index.vue'),
      },
      {
        path: 'logManage',
        name: 'logManage',
        component: () =>
          import('../views/super/maSys/logManage/logManage.vue'),
      },
      // ------------------------------------审批管理
      {
        path: 'pending',
        name: 'pending',
        component: () =>
          import('../views/super/approvalManage/pending/index.vue'),
      },
      {
        path: 'approved',
        name: 'approved',
        component: () =>
          import('../views/super/approvalManage/approved/index.vue'),
      },
      // ------------------------------------任务管理
      {
        path: 'fillingTask',
        name: 'fillingTask',
        component: () =>
          import('../views/super/taskManage/fillingTask/index.vue'),
      },
      //学校申报数据查询
      {
        path: 'schoolTask',
        name: 'schoolTask',
        component: () =>
          import('../views/super/taskManage/fillingTask/schoolIndex.vue'),
      },
      // ------------------------------------申报管理
      {
        path: 'dataFilling',
        name: 'dataFilling',
        component: () =>
          import('../views/super/declareManage/index.vue'),
      },
      {
        path: 'dataFilling/fill',
        name: 'dataFilling/fill',
        component: () =>
          import('../views/super/declareManage/dataFilling/index.vue'),
      },
      // ------------------------------------汇总统计
      {
        path: 'schoolSummary',
        name: 'schoolSummary',
        component: () =>
          import('../views/super/statistics/schoolList.vue'),
      },
      {
        path: 'countySummary',
        name: 'countySummary',
        component: () =>
          import('../views/super/statistics/districtSchoolList.vue'),
      },
      {
        path: 'citySummary',
        name: 'citySummary',
        component: () =>
          import('../views/super/statistics/cityDistrictSchoolList.vue'),
      },
      {
        path: 'citySbSummary',
        name: 'citySbSummary',
        component: () =>
          import('../views/super/statistics/citySbSchoolList.vue'),
      },
    ],
  },
];
const router = new VueRouter({
  routes,
});

export default router;
