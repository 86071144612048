// 引入组件
import Tinymce from './index.vue'
import load from './dynamicLoadScript'

let address = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_PROD;
const tinymceCDN = address + '/tinymce-all-in-one-4.9.3/package/tinymce.min.js'

// 定义 Loading 对象
const installTinymce = {
  // install 是默认的方法。当外界在 use 这个组件的时候，就会调用本身的 install 方法，同时传一个 Vue 这个类的参数。
  install: function(Vue) {
    load(tinymceCDN, (err) => {
      if (err) {
        this.$message.error(err.message)
        return
      }
    })
    Vue.component('tinymce', Tinymce)
  }
}

// 导出
export default installTinymce
