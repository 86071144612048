import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import tool from './tool'
import router from '.././router'

const otherReq = []
let address = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_URL : process.env.VUE_APP_BASE_API_PROD;

// create an axios instance
const service = axios.create({
  baseURL: address, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: -1 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = tool.getCookie('X-Token');
    config.headers['X-TOKEN'] = token
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    var url = response.request.responseURL
    var split = url.split('/')
    var laststr = split[split.length - 1]
    var lastUrl = laststr.split('?')[0]
    if (lastUrl === 'flow') {
      return response
    }
    if (otherReq.indexOf(lastUrl) !== -1) {
      return response
    }
    if (url.indexOf('/data-source/data/') !== -1) {
      return response.data
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 500
      })

      if (res.code === 50101 || res.code === 50102) {
        // to re-login
        MessageBox.confirm('用户未登录', {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            router.push({ path: '/' })
          })
        })
      } else if (res.code === 50107) { // 修改密码
        router.push({
          name: 'uploadPassword', params: { token: res.data.token }
        })
      } else if (res.code === 99999) { // 未授权
        router.push({ path: '/empower/empower' })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.msg || 'Error',
      type: 'error',
      duration: 500
    })
    return Promise.reject(error)
  }
)

export default service
