<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<script>
import { Howl } from "howler";
export default {
  data() {
    return {
    
      myStyle: {},
      notifications: {},
      totalNum: 0,
      urlPath: "",
      getaudio: "",
      alarmStage: false,
      timeCount: 0,
      initArr: [],
      getBody: {},
      userId: "",
      sound: new Howl({
        // http://d.datouwang.com/uploads/file/yinxiao/2021/yinxiao4497.mp3
        // https://yuan-1252477692.cos.ap-guangzhou.myqcloud.com/zzzzz/mp3/French%20Kiss%20-%20Jealousy.mp3
        src: [
          // "http://d.datouwang.com/uploads/file/yinxiao/2021/yinxiao4497.mp3",
          //"http://pd.createdpark.cn/yinxiao4497.mp3",
        ],
        autoplay: false,
        loop: true,
      }),
    };
  },
  mounted() {
    if (!this.$getCookie("userInfo")) {
      return false;
    } else {
      this.userId = JSON.parse(this.$getCookie("userInfo")).userId;
      this.totalNum =
        this.$store.state.totalNum > 0 ? this.$store.state.totalNum : 0;
      this.alarm();
      this.getProjectUnHandleByUser();
      this.countNum();
      this.$nextTick(() => {
        this.getaudio = document.getElementById("getaudio");
      });
    }
  },
  computed: {
    stop() {
      // alert(this.$store.state.alarmStatus)
      return this.$store.state.alarmStatus;
    },
  },
  watch: {
    stop(v) {
      this.alarmStage = v;
      this.closeSound();
    },
    alarmStage(v) {
      if (v) {
        this.sound.play();
      } else {
        this.closeSound();
      }
    },
  },
  methods: {
    palyDing() {
      this.sound.play();
    },
    alarm() {
      const userInfo = JSON.parse(this.$getCookie("userInfo"));
      // alert(this.$getCookie("userInfo"))
      // 解决id重复导致回调方法不执行
      userInfo.projectTopic.forEach((item) => {
        this.$timeing(
          "/topic/app/alarm/" + item.projectCode,
          "all11",
          (res) => {
            let str = res.headers.destination;
            let index = str.lastIndexOf("/");
            let rightStr = str.substring(index + 1, str.length);
            const body = JSON.parse(res.body);
            console.log(body);
            this.alarmStage = false;
            this.$store.commit("setAlarmStatus", this.alarmStage);
            this.open(body);
            // 那一个随机数当作id
            var id = Math.random() * 1000000000;
            this.changeId = id;
            if (localStorage.code === rightStr) {
              this.$store.commit("setAlarmInfo", body);
            }
          }
        );
      });
    },
    countNum() {
      const userInfo = JSON.parse(this.$getCookie("userInfo"));
      userInfo.projectTopic.forEach((item) => {
        this.$timeing(
          "/topic/app/alarmCount/" + item.projectCode,
          "all",
          (res) => {
            const body = JSON.parse(res.body);
            console.log(body);
            this.returnCount(body.data);
          }
        );
      });
    },
    closeSound() {
      this.sound.stop();
    },
    getProjectUnHandleByUser() {
      this.totalNum = 0;
      this.initArr = [];
      // this.$http
      //   .post("bma-user-service/user/getProjectUnHandleByUser", {
      //     userId: this.userId,
      //   })
      //   .then((res) => {
        var res = this.$localData.getProjectUnHandleByUser
          let projectHandles = res.data.projectHandles;
          if (projectHandles.length > 0) {
            this.initArr = projectHandles;
            projectHandles.forEach((item) => {
              this.totalNum += item.unHandle;
            });
          } else {
            this.totalNum = 0;
            this.initArr = [];
          }
          this.$store.commit("setTotalNum", this.totalNum);
        // });
    },
    // js 重新排序数组并计算
    returnCount(data) {
      this.totalNum = 0;
      this.$store.commit("setTotalNum", 0);
      if (data.projectCode) {
        this.initArr.forEach((item) => {
          if (item.projectCode === data.projectCode) {
            item.unHandle = data.unHandle;
            this.totalNum += item.unHandle;
          } else {
            this.totalNum += item.unHandle;
          }
        });
      }
      this.$store.commit("setTotalNum", this.totalNum);
    },
    closeBox() {
      this.closeSound();
      this.alarmStage = false;
      this.$store.commit("setAlarmStatus", this.alarmStage);
    },
    knowInfo() {
      this.alarmStage = false;
      this.$store.commit("setAlarmStatus", this.alarmStage);
      this.$router.push("/warn");
    },
    open(body) {
      this.alarmStage = true;
      this.$store.commit("setAlarmStatus", this.alarmStage);
      if (document.getElementsByClassName("alarmboxss")[0]) {
        document.getElementsByClassName("alarmp1")[0].innerHTML =
          body.data.projectName + body.data.projectName;
            document.getElementsByClassName("alarmp1")[0].title =
          body.data.projectName + body.data.projectName;
        document.getElementsByClassName("alarmp2")[0].innerHTML =
          body.data.equipmentDesc;
        document.getElementsByClassName("alarmcontent")[0].innerHTML =
          body.data.alarmDesc;
        document.getElementsByClassName(
          "alarmtime"
        )[0].innerHTML = body.time.substr(11, 8);
      }
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100% !important;
}
.echarts {
  width: 100% !important;
  height: 100% !important;
}
.acvtive-box {
  right: 0px;
  display: block;
  z-index: 5000;
}
.other {
  right: 0;
  opacity: 0;
  display: none;
  z-index: 0;
}
#getaudio {
  display: none;
}
.alarmp1{
  width: 148px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
     cursor: pointer;
}
</style>
