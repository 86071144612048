import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/ltcloud/user/getList',
    method: 'post',
    data
  })
}

export function getCityList(data) {
  return request({
    url: '/ltcloud/user/getCityList',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/ltcloud/user/logout',
    method: 'post'
  })
}

export function saveData(data) {
  return request({
    url: '/ltcloud/user/save',
    method: 'post',
    data
  })
}

export function editPassWord(newPassWord, oldPassWird, headers) {
  return request({
    url: '/ltcloud/user/editPasssWord',
    method: 'post',
    params: { newPassWord, oldPassWird },
    headers: headers
  })
}

export function repStallsPasssWord(data) {
  return request({
    url: '/ltcloud/user/repStallsPasssWord',
    method: 'post',
    params: data
  })
}

export function updateState(data) {
  return request({
    url: '/ltcloud/user/updateState',
    method: 'post',
    params: data
  })
}

export function getUserList(data) {
  return request({
    url: '/ltcloud/user/getUserList',
    method: 'post',
    data
  })
}

export function getId() {
  return request({
    url: '/ltcloud/user/getId',
    method: 'post'
  })
}

// 获取用户菜单菜单
export function getMenu() {
  return request({
    url: '/ltcloud/user/getMenu',
    method: 'post'
  })
}

export function reportDepartmentUser(data){
  return request({
    url:'/ltcloud/user/reportUser',
    method:'post',
    data
  })
}


// 获取用户菜单菜单
export function sendCode(data) {
  return request({
    url: '/ltcloud/user/sendCode',
    method: 'post',
    params: data
  })
}

export function newLogin(data) {
  return request({
    url: '/ltcloud/user/newLogin',
    method: 'post',
    params: data
  })
}

export function getInfo(data){
  return request({
    url:'/ltcloud/user/getInfo',
    method:'post',
    data
  })
}
