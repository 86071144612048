// 四舍五入  两位数
export const genderMenu = {
  func: (value) => {
    if (isNaN(value)) return value;
    const s = 2;
    let changenum = '';
    let index = '';
    let isf = false;
    // 负数
    if (value < 0) {
      isf = true;
      value = -value;
    }
    changenum = (
      parseInt(value * Math.pow(10, s) + 0.5) / Math.pow(10, s)
    ).toString();
    index = changenum.indexOf('.');
    if (index < 0 && s > 0) {
      changenum = changenum + '.';
      for (let i = 0; i < s; i++) {
        changenum = changenum + '0';
      }
    } else {
      index = changenum.length - index;
      for (let i = 0; i < s - index + 1; i++) {
        changenum = changenum + '0';
      }
    }
    if (isf) {
      return -changenum;
    } else {
      return changenum;
    }
  },
};
// 取数据的绝对值
export const abs = {
  func: (value) => {
    if (isNaN(value)) return value;
    if (value < 0) {
      return -value;
    } else {
      return value;
    }
  },
};
export const getTwo = {
  func: (value) => {
    // console.log(value)

    if (isNaN(value)) {
      return value;
    } else if (value === '') {
      return value;
    } else {
    //   return Math.floor(value * 100) / 100
    
    value = value.toString()
    let index = value.indexOf('.')
    if (index !== -1) {
      value = value.substring(0, 2 + index + 1)
    } else {
      value = value.substring(0)
    }
    return parseFloat(value).toFixed(2)
  }
    // if(value){
    //   let str=value.toString()
    //   let str1=str.indexOf(".")>-1?str.substring(0,str.indexOf(".") + 3):str+'.00'
    //   return str1
    // }

  },
};
// 保留4位数
export const getFour = {
  func: (value) => {
    if (isNaN(value)) return value;
    const s = 4;
    let changenum = '';
    let index = '';
    let isf = false;
    // 负数
    if (value < 0) {
      isf = true;
      value = -value;
    }
    changenum = (
      parseInt(value * Math.pow(10, s)) / Math.pow(10, s)
    ).toString();
    index = changenum.indexOf('.');
    if (index < 0 && s > 0) {
      changenum = changenum + '.';
      for (let i = 0; i < s; i++) {
        changenum = changenum + '0';
      }
    } else {
      index = changenum.length - index;
      for (let i = 0; i < s - index + 1; i++) {
        changenum = changenum + '0';
      }
    }
    if (isf) {
      return -changenum;
    } else {
      return changenum;
    }
  },
};
export const per = {
  func: (value) => {
    if (isNaN(value)) return value;
    return ((value - 0) * 100).toFixed(1) + '%';
  },
};