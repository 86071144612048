import request from '@/utils/request'
import service from "@/utils/download";

export function getList(data) {
  return request({
    url: '/ltcloud/department/list',
    method: 'post',
    data
  })
}
export function getDepartmentPage(data) {
  return request({
    url: '/ltcloud/department/getDepartmentPage',
    method: 'post',
    data
  })
}
export function saveData(data) {
  return request({
    url: '/ltcloud/department/edit',
    method: 'post',
    data
  })
}

export function getInitialLoading() {
  return request({
    url: '/ltcloud/department/getInitialLoading',
    method: 'post'
  })
}

export function getAgainLoading(data) {
  return request({
    url: '/ltcloud/department/getAgainLoading',
    method: 'post',
    params: data
  })
}

export function start(id) {
  return request({
    url: '/ltcloud/department/start',
    method: 'post',
    params: { id }
  })
}

export function getchirldnum(id) {
  return request({
    url: '/ltcloud/department/getchirldnum',
    method: 'post',
    params: { id }
  })
}

export function getdeUsernum(id) {
  return request({
    url: '/ltcloud/department/getdeUsernum',
    method: 'post',
    params: { id }
  })
}
export function end(id) {
  return request({
    url: '/ltcloud/department/end',
    method: 'post',
    params: { id }
  })
}

export function getparentName(id) {
  return request({
    url: '/ltcloud/department/getparentName',
    method: 'post',
    params: { id }
  })
}
export function getDistricDeptId() {
  return request({
    url: '/ltcloud/department/getDistricDeptId',
    method: 'post',
    
  })
}

export function universityTemplate(){
  return service({
    url: '/ltcloud/department/universityTemplate',
    method: 'post',
  })
}


export function userWorkTemplate(){
  return service({
    url: '/ltcloud/department/userWorkTemplate',
    method: 'post',
  })
}

export function reportDepartment(data){
  return request({
    url: '/ltcloud/department/reportDepartment',
    method: 'post',
    data
  })
}

export function downloadWrongReport(data){
  return service({
    url: '/ltcloud/department/downloadWrongReport',
    method: 'post',
    params:data
  })
}

export function deleteDepartment(data){
  return service({
    url: '/ltcloud/department/deleteDepartment',
    method: 'post',
    params:data
  })
}
//下载用户手册
export function xzyhsc(){
  return service({
    url: '/ltcloud/department/xzyhsc',
    method: 'post',
  })
}
