import * as filters from './dict'

const Install = Vue => {
  Object.keys(filters).forEach(key => {
    // 过滤器
    Vue.filter(key, filters[key].func)
    // 方法
    Vue.prototype["$" + key] = filters[key].func;
  })
}

export default Install