import Cookies from 'js-cookie';
import http from './http';
let socket = null;
let url = window.location.hash;
let subscriptions = {};
function getCookie(key) {
  var strCookie = document.cookie;
  //将多cookie切割为多个名/值对
  var arrCookie = strCookie.split('; ');
  for (var i = 0; i < arrCookie.length; i++) {
    var tmp = arrCookie[i].split('=');
    if (tmp[0] == key) {
      return tmp[1];
    }
  }
}
function refreshPage() {
  if (url === '#/') {
    return false;
  } else {
    setTimeout(() => {
      location.reload();
    }, 1000);
  }
}
function clearAllCookie() {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  }
  if (cookies.length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      let domain = location.host.substr(location.host.indexOf('.'));
      document.cookie =
        name +
        '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
        domain;
    }
  }
}

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
      );
    }
  }
  return fmt;
}

export const formatDate = function(format, currDate) {
  /*
   * eg:format="yyyy-MM-dd hh:mm:ss";
   */
  console.log(currDate, 11111);
  var o = {
    'M+': currDate.getMonth() + 1, // month
    'd+': currDate.getDate(), // day
    'h+': currDate.getHours(), // hour
    'm+': currDate.getMinutes(), // minute
    's+': currDate.getSeconds(), // second
    'q+': Math.floor((currDate.getMonth() + 3) / 3), // quarter
    S: currDate.getMilliseconds(),
    // millisecond
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (currDate.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k]
          : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return format;
};
// 关闭订阅
function disconnect() {
  if (window.stompClient) {
    console.log(window.stompClient)
    window.stompClient.disconnect();
  }else{
    return false
  }
}
// 初始化连接
function initWebSocket() {
  // console.log(
  //   '-------------------------------------开始初始化-------------------------',
  // );
  // const user_id =  JSON.parse(getCookie('userInfo')).userId;
  // const access_token = getCookie('token');
  // let address = process.env.VUE_APP_SOCKET_URL
  //   ? process.env.VUE_APP_SOCKET_URL
  //   : 'ws://bma-api-gateway-round01.createdpark.net/bma-distribution-service/cloud-websocket';
  // socket = new WebSocket(
  //   address +
  //     '?topic=webdata&user_id=' +
  //     user_id +
  //     '&access_token=' +
  //     access_token, 
  // );
  // window.stompClient = Stomp.over(socket);
  // // console.log('stompClient: ', stompClient);
  // window.stompClient.connect({}, function(a) {
  //   // console.log('Connected: ');
  // });
}
function circleSocket() {
  if (stompClient.ws.readyState !== 1) {
    disconnect();
    initWebSocket();
    refreshPage();
    // setTimeout(() => {
    // }, 2000);
  }
}
function connectDate() {
  console.log(subscriptions);
  // if (subscriptions) {
  //   Object.getOwnPropertyNames(subscriptions).forEach(function(key) {
  //     stompClient.subscribe(key);
  // console.log('089898989');
  Cookies.set('comeTime', new Date().getTime() - 1000 * 60 * 540);
  http
    .post('bma-user-service/project/searchProjectList', {
      currentPage: 30,
      size: 1,
    })
    .then((res) => {
      console.log(res);
    });
  // });
  // }
}
function timeing(topic, id, callback) {
  console.log('==========================');
  // console.log(socket);
  // console.log(window);
  if (window.stompClient) {
    socket.onclose = function(event) {
      console.log(
        '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!断开了---------',
      );
      // 获取断开之前的数据
      if (localStorage.stop==='false') {
        subscriptions = stompClient.subscriptions;
        setInterval(circleSocket, 4000);
      }
    };
    if (stompClient.ws.readyState == 1) {
      window.stompClient.subscribe(
        topic,
        function(res) {
          callback(res);
        },
        { id: id },
      );
    } else {
      setTimeout(() => {
        window.stompClient.subscribe(
          topic,
          function(res) {
            callback(res);
          },
          { id: id },
        );
      }, 2000);
    }
  } else {
    // initWebSocket();
    // setTimeout(() => {
    //   window.stompClient.subscribe(
    //     topic,
    //     function(res) {
    //       callback(res);
    //     },
    //     { id: id },
    //   );
    // }, 2000);
  }
}
function clearTimeing(topic) {
  if (window.stompClient) {
    window.stompClient.unsubscribe(topic);
  }
}
function filterChinese(val) {
  let reg = /[\u4e00-\u9fa5]/g;
  return val.replace(reg, '');
}
export default {
  getCookie,
  dateFormat,
  initWebSocket,
  timeing,
  clearTimeing,
  formatDate,
  clearAllCookie,
  filterChinese,
  disconnect
};
