<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary" @click="dialogVisible=true">
      上传
    </el-button>
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" append-to-body>
      <el-upload
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="uploadpath"
        :headers="uoloadheaders"
        list-type="picture-card"
        name="uploadfile_ant"
        accept=".png,.jpg,.bmp,.gif"
      >
        <el-button size="small" type="primary">
          上传
        </el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">
        关闭
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        提交
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import tool from '@/utils/tool'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      uploadpath: '',
      uoloadheaders: '',
      applicationId:null
    }
  },
  created() {
    this.applicationId=this.$getCookie("projectId");
    this.getUploadpath()
  },
  methods: {
    //显示之前上传的文件
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有图像成功上载。如果有网络问题，请刷新页面并重新上传！')
        return
      }
      this.$emit('successCBK', arr)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file,fileList) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.data
          this.listObj[objKeyArr[i]].hasSuccess = true
          return
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload(file) {
      var filename = file.name
      var flag = false
      var arr = ['png', 'jpg', 'bmp', 'gif']
      // 取出上传文件的扩展名
      var index = filename.lastIndexOf('.')
      var ext = filename.substring(index + 1)
      // 循环比较
      for (var i = 0; i < arr.length; i++) {
        if (ext === arr[i]) {
          flag = true
          break
        }
      }
      if (!flag) {
        Message({
          message: '此格式文件不允许上传',
          type: 'error',
          duration: 5 * 1000
        })
        return false
      }

      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.listObj[fileName] = {}
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function() {
          _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
        }
        resolve(true)
      })
    },
    getUploadpath() {
      var path = '/ltcloud/fileinfo/upload'
      let address = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_URL : process.env.VUE_APP_BASE_API_PROD;
      this.uploadpath = address + path
      this.uoloadheaders = {
        'X-TOKEN' : tool.getCookie('X-Token'),
        'client-url':location.href,
        'applicationId':this.applicationId
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  .el-upload--picture-card {
    width: 100%;
  }
}
</style>
