export default {
  install(vue, {
    router,
    store
  }) {
    router.beforeEach((to, from, next) => {
      next();
    })
  }
}
