<template>
  <div class="home">
    <el-container class="out">
      <el-container class="main">
        <el-header>
          <div class="left" @click="back">
            <!-- <i class="iconfont icon-fanhuijiantou" />
            <p>返回</p> -->
          </div>
          <div class="right">
            <el-dropdown>
  <el-button  circle type="text" style="background: #f8f8f9;width:60px">
  <img src="../../assets/images/person.png">
  </el-button>
  <el-dropdown-menu slot="dropdown">
   
    <el-dropdown-item><span @click="setUser">账号管理</span></el-dropdown-item>
    <el-dropdown-item ><span @click="logout">退出登录</span></el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
            
            <p>欢迎 {{ name }}</p>
            <!-- <img src="../../assets/images/icon/logout.png" @click="logout"> -->
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
      <el-aside width="240px">
        <div class="logo">教育装备数据填报系统</div>
        <el-menu
          :unique-opened="true"
          :default-active="$route.path"
          :default-openeds="open_list"
          class="el-menu-vertical"
          router
          background-color="rgba(0,0,0,0)"
          text-color="#fff"
          active-text-color="#fff"
          @click="selectItems"
        >
<!--            
              <el-menu-item index="/super/general" v-if="isActive('11')">
               <i class="el-icon-map-location"></i>
            <span slot="title">首页</span>
            </el-menu-item> -->
           
          <el-submenu index="1" v-if="isActiveGroup(apro_menu)">
            <template slot="title">
              <i class="iconfont icon-icon_timesmonitoring_nor" />
              <span>审批管理</span>
            </template>
            <el-menu-item-group v-if="isActive('11')">
              <el-menu-item index="/super/pending">学校填报审批</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('12')">
              <el-menu-item index="/super/approved">学校填报情况</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="2" v-if="isActiveGroup(flow_menu)">
            <template slot="title">
              <i class="iconfont icon-icon_timesmonitoring_nor" />
              <span>任务管理</span>
            </template>
            <el-menu-item-group v-if="isActive('21')">
              <el-menu-item index="/super/fillingTask">填报任务</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('21')">
              <el-menu-item index="/super/schoolTask">学校申报数据</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="3" v-if="isActiveGroup(data_menu)">
            <template slot="title">
              <i class="iconfont icon-baobiao" />
              <span>申报管理</span>
            </template>
            <el-menu-item-group v-if="isActive('31')">
              <el-menu-item index="/super/dataFilling">数据填报</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="4" v-if="isActiveGroup(repo_menu)">
            <template slot="title">
              <i class="iconfont icon-baobiao" />
              <span>汇总统计</span>
            </template>
            <el-menu-item-group v-if="isActive('41')">
              <el-menu-item index="/super/schoolSummary">学校年度教育装备统计表 </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('42')">
              <el-menu-item index="/super/countySummary">区统计报表</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('43')">
              <el-menu-item index="/super/countySummary">天津市教育装备统计表</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('44')">
              <el-menu-item index="/super/citySummary">区级统计报表</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('44')">
              <el-menu-item index="/super/citySbSummary">市级统计报表</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="5" v-if="isActiveGroup(sys_menu)">
            <template slot="title">
              <i class="iconfont icon-icon_timesmonitoring_nor" />
              <span>系统管理</span>
            </template>
            <el-menu-item-group v-if="isActive('51')">
              <el-menu-item index="/super/deptManagement">组织架构</el-menu-item>
            </el-menu-item-group>
            
             <el-menu-item-group v-if="isActive('56')">
              <el-menu-item index="/super/userManagement">用户管理</el-menu-item>
            </el-menu-item-group>
             <el-menu-item-group v-if="isActive('57')">
              <el-menu-item index="/super/deptManagementCity">组织架构</el-menu-item>
            </el-menu-item-group>
            
             <el-menu-item-group v-if="isActive('58')">
              <el-menu-item index="/super/userManagementCity">用户管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('52')">
              <el-menu-item index="/super/role">角色管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('53')">
              <el-menu-item index="/super/flowManage">审批人员管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('54')">
              <el-menu-item index="/super/maDict">业务字典</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="isActive('55')">
              <el-menu-item index="/super/logManage">日志管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

        </el-menu>
      </el-aside>
    </el-container>
    <el-dialog :close-on-click-modal="false"
        :title="title"
        width="45%"
        :visible.sync="dialogFormVisible"
        :modal-append-to-body="false"
        @close="closeViewUser"
      >
        <el-form :label-position="'left'" label-width="100px" :model="form">
          
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="用户名：">
                <div slot="label">用户名<font color="red">*</font></div>
                <el-input v-model="form.userName" readonly placeholder="请输入用户名" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">真实姓名<font color="red">*</font></div>
                <el-input v-model="form.nickName" placeholder="请输入真实姓名" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item>
                <div slot="label">密码<font color="red">*</font></div>
                <el-input v-model="form.password" type="password" :placeholder="passwordph" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">确认密码<font color="red">*</font></div>
                <el-input v-model="form.password2" type="password" :placeholder="passwordph" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           
            <el-col :span="12">
              <el-form-item>
                <div slot="label">联系电话<font color="red">*</font></div>
                <el-input v-model="form.phone" placeholder="请输入联系电话" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="addUserHandle">保 存</el-button>
          <el-button type="danger" @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { logout, getMenu,getInfo,saveData as saveUser } from '@/api/user'
import { Message } from 'element-ui'
// @ is an alias to /src
export default {
  name: 'Home',
  data() {
    return {
      name: '',
      form:{},
      title:'',
      passwordph:'',
      dialogFormVisible: false,
      resourceIds: [],
      open_list: ['1'],
      apro_menu:['11','12'],
      flow_menu:['21'],
      data_menu:['31'],
      repo_menu:['41','42','43','44'],
      sys_menu:['51','52','53','54','55','56','57','58']
    }
  },

  created() {
    this.name = JSON.parse(this.$getCookie('userInfo')).userName
    this.getUserMenu()
    // setTimeout(() => {
    //   this.resourceIds = this.$getCookie('resourceIds').split(',')
    // }, 1000)
  },
  methods: {
    // 获取用户菜单
    getUserMenu() {
      getMenu().then(response=>{
        this.resourceIds = response.data
      })
    },
    // 判断哪个菜单分组显示
    isActiveGroup(data) {
      var flag = false
      for (var i in data) {
        for (var j in this.resourceIds) {
          if (this.resourceIds[j] === data[i]) {
          flag = true
          break
          }
        }
      }
      return flag
    },
    // 判断哪个菜单显示
    isActive(menu) {
      var flag = false
      for (var i in this.resourceIds) {
        if (this.resourceIds[i] === menu) {
        flag = true
        break
        }
      }
      return flag
    },
    selectItems(index, path) {
    },
    back() {
      this.$router.push('/project')
    },
    closeItem() {
      this.open_list = ['1']
    },
    toMain() {
      this.$router.push('/project')
    },
    // 角色列表
    setUser() {
      getInfo().then(response =>{
        this.form=response.data
        this.form.password = undefined
      })
        this.title='编辑账号'
        // 编辑
        
        this.passwordph = '(密码变更)'
        this.dialogFormVisible = true
      
    },
     addUserHandle() {
      if (!this.form.userName) {
        this.$message.error('请输入用户名')
        return
      } else if (!this.form.nickName) {
        this.$message.error('请输入真实姓名')
        return
      }  else if (this.form.password !== this.form.password2) {
        this.$message.error('两次输入密码不一致')
        return
      }else if (!this.form.phone) {
        this.$message.error('请输入联系电话')
        return
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        saveUser(this.form).then((res) => {
          Message.success('修改成功')
          //this.listUser()
          this.dialogFormVisible = false
          loading.close()
        }).catch(response => {
          loading.close()
        })
      }
    },
    logout () {
      logout().then((response) => {
        Message.success('登出成功')
        window.localStorage.clear();
        this.$clearAllCookie();
        this.$disconnect()
        this.$store.commit("setAlarmStatus", false);
        localStorage.stop = true;
        this.$router.push("/");
      });
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  height: 100%;
  .el-aside {
    background: linear-gradient(180deg, #131c26 0%, #152f57 66%, #0e3c83 100%);
    font-size: 14px;
    position: fixed;
    z-index: 22;
    height: 100%;
    .logo {
      font-size: 16px;
      color: #ffffff;
      height: 78px;
      line-height: 78px;
      text-align: center;
    }
    li {
      font-size: 14px;
      padding-left: 0px !important;
      width: 240px;
      border-bottom: 1px solid #000000;
    }
    .select {
      width: 100%;
      text-align: center;
      position: relative;
      margin: 26px 0;
      > i {
        position: absolute;
        top: 13.5px;
        left: 34px;
        color: rgba(255, 255, 255, 0.5);
        z-index: 1;
        font-size: 13px;
      }
      /deep/.el-select {
        width: 200px;
        border-radius: 5px;
        .el-input__inner {
          background: #3480ff;
          border: none;
          color: #fff;
          padding-left: 40px;
        }
      }
    }
    .el-menu {
      width: 240px;
      border-right: none;
      .el-menu-item {
        &.is-active {
          background-color: #111111 !important;
        }
        &:hover {
          background-color: #111111 !important;
        }
      }
      .el-menu-item {
        padding-left: 30px;
        &.is-active {
          background-color: #111111 !important;
        }
        &:hover {
          background-color: #111111 !important;
        }
      }
    }
    li {
      font-size: 14px;
      padding-left: 30px;
      width: 240px;
      i {
        color: rgba(107, 124, 167, 1);
        margin-right: 10px;
        font-size: 20px;
      }
      &.is-active {
        i {
          color: #fff;
        }
      }
    }
    .el-menu-item-group {
      padding-left: 0px !important;
      background-color: #111111 !important;
      ul {
        li {
          padding-left: 49px !important;
          border-bottom: 1px solid rgb(0, 0, 0, 0.34);
          &:first-child {
            margin-top: -14px;
          }
        }
      }
    }
    li.el-submenu {
      font-size: 14px;
      padding-left: 30px;
      width: 240px;
      i {
        color: rgba(107, 124, 167, 1);
        margin-right: 10px;
        font-size: 20px;
      }
      &.is-active {
        i,
        span {
          color: #fff;
        }
      }
      &:nth-child(1) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(2) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(3) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(4) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(5) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
        li.el-submenu {
          padding-left: 0px !important;
        }
        ul {
          li {
            padding-left: 40px !important;
          }
          li.el-menu-item-group {
            padding-left: 0px !important;
            i {
              color: rgba(107, 124, 167, 1);
              margin-right: 10px;
              font-size: 20px;
            }
            span {
              color: rgba(107, 124, 167, 1);
            }
          }
        }
        .el-menu-item-group ul li {
          padding-left: 0px;
          &.is-active {
            i,
            span {
              color: rgb(42, 122, 255);
            }
          }
          &:nth-child(4) {
            padding-left: 40px !important;
          }
          & > ul {
            li.el-menu-item {
              padding-left: 70px !important;
            }
          }
        }
      }
    }
  }
}
.main {
  .el-header {
    height: 78px;
    background: #f8f8f9;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    z-index: 10;
    .left {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 240px;
      i {
        margin-right: 12px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        color: #161820;
        opacity: 1;
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 37px;
        margin-right: 20px;
        cursor: pointer;
        &:not(:first-of-type) {
          width: 30px;
          height: 30px;
        }
      }
      .el-badge {
        margin-right: 20px;
        img {
          width: 30px;
          height: 30px;
          margin: 0;
        }
      }
      p {
        line-height: 27px;
        color: #445a5a;
        margin-right: 20px;
      }
    }
  }
  span {
    font-size: 16px;
    color: #445a5a;
    margin: 0 15px;
  }
  .icon-out {
    width: 30px;
    height: 30px;
    background: #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-user {
    font-size: 25px;
    color: #445a5a;
  }
  .icon-tuichu {
    font-size: 16px;
    color: #445a5a;
    cursor: pointer;
  }
  .el-main {
    padding: 0;
    margin-top: 60px;
    margin-left: 240px;
    background: #ececec;
  }
}

/deep/.el-submenu__title i { 
    color:#fff !important

}
</style>
