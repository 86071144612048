import router from './index';
import tool from '@/utils/tool';
router.beforeEach(async (to, from, next) => {
  const hasToken = tool.getCookie('refreshToken');
  if (hasToken) {
    // console.log('有')
    // alert(to.path)
    next();
    // alert(from.path+'这是我要的地址')
    // alert("这是过来的页面" + from.path)
    // alert("这是将要离开的页面" + to.path)
    // alert(location.href.indexOf("project#reloaded")+'有值')
    if (from.path === '/') {
      if (to.path === '/project') {
        // alert('有的！')
        if (location.href.indexOf("#reloaded") == -1) {
          location.href = location.href + "#reloaded";
          location.reload();
        }
      } else if (to.path === '/') {
        next('/project');
      }

    }
  } else {
    // console.log('没值')
    if (to.path === '/') {
      next();
    } else {
      next('/');
    }
  }
});

router.afterEach(() => {});